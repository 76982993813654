<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card mb-3">
        <div class="card-header position-relative">
          <h3 class="mb-0">Edit Payees</h3>
        </div>

        <div class="card-body min-vh" v-loading="loading">
          <el-form
            v-if="payeeForm"
            ref="payeeForm"
            :model="payeeForm"
            :rules="payeeFormRule"
            :label-position="labelPosition"
            size="small"
            label-width="230px"
          >
            <div class="row justify-content-md-center">
              <div class="col-lg-8 col-md-12">
                <el-form-item label="Currency" prop="currency">
                  <el-select
                    filterable
                    v-model="payeeForm.currency"
                    placeholder="Please select Currency"
                    class="form-control-alternative form-currency-flag"
                  >
                    <template slot="prefix"
                      ><div
                        :class="`currency-flag-selected currency-flag currency-flag-${payeeForm.currency.toLowerCase()}`"
                      ></div
                    ></template>
                    <el-option
                      v-for="item in currencys"
                      :key="item.currency"
                      :label="`${item.currency} - ${item.detail.name_plural}`"
                      :value="item.currency"
                    >
                      <template>
                        <div
                          :class="`currency-flag currency-flag-lg currency-flag-position-abs currency-flag-${item.currency.toLowerCase()}`"
                        ></div>
                        <span class="ml-5">
                          {{ item.currency }} -
                          {{ item.detail.name_plural }}</span
                        >
                      </template>
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-divider>Bank Detail</el-divider>
                <el-form-item label="Bank Name" prop="bankname">
                  <el-input
                    v-model="payeeForm.bankname"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="IBAN" prop="iban">
                  <el-input
                    v-model="payeeForm.iban"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="BIC" prop="bic">
                  <el-input
                    v-model="payeeForm.bic"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Bank Address" prop="bankaddress">
                  <el-input
                    type="textarea"
                    v-model="payeeForm.bankaddress"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Bank Country" prop="bankCountrycode">
                  <el-select
                    v-model="payeeForm.bankCountrycode"
                    filterable
                    class="form-control-alternative form-currency-flag"
                  >
                    <template slot="prefix"
                      ><div class="vti__flag-wrapper">
                        <span
                          :class="`vti__flag d-inline-block ${bankCountryCode}`"
                        ></span></div
                    ></template>
                    <el-option
                      v-for="item in country"
                      :key="item.id"
                      :label="item.countryName"
                      :value="item.code"
                    >
                      <template>
                        <div class="vti__flag-wrapper">
                          <span
                            :class="`vti__flag d-inline-block ${item.code.toLowerCase()}`"
                          ></span>
                        </div>
                        <span class="ml-2"> {{ item.countryName }}</span>
                      </template>
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Routing Number (USA only)"
                  prop="routingnumber"
                  v-if="
                    payeeForm.currency === 'USD' &&
                    payeeForm.bankCountrycode === usaCountry
                  "
                >
                  <el-input
                    v-model="payeeForm.routingnumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-divider>Correspond Bank detail</el-divider>
                <el-form-item
                  label="Correspond Bank name"
                  prop="corresBankName"
                >
                  <el-input
                    v-model="payeeForm.corresBankName"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Correspond Bank address"
                  prop="corresBankAddress"
                >
                  <el-input
                    type="textarea"
                    v-model="payeeForm.corresBankAddress"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Correspond Swift Code"
                  prop="corresSwiftCode"
                >
                  <el-input
                    v-model="payeeForm.corresSwiftCode"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-divider>Beneficient Detail</el-divider>
                <el-form-item label="Beneficient Name" prop="name">
                  <el-input
                    v-model="payeeForm.name"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Beneficient Address" prop="payeeaddress">
                  <el-input
                    type="textarea"
                    v-model="payeeForm.payeeaddress"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Beneficient Post Code" prop="payeezipcode">
                  <el-input
                    v-model="payeeForm.payeezipcode"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Beneficient City / State"
                  prop="payeecitystate"
                >
                  <el-input
                    v-model="payeeForm.payeecitystate"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Country" prop="payeeCountrycode">
                  <el-select
                    v-model="payeeForm.payeeCountrycode"
                    filterable
                    class="form-control-alternative form-currency-flag"
                    @change="countryChanged"
                  >
                    <template slot="prefix"
                      ><div class="vti__flag-wrapper">
                        <span
                          :class="`vti__flag d-inline-block ${payeeCountryCode}`"
                        ></span></div
                    ></template>
                    <el-option
                      v-for="item in country"
                      :key="item.id"
                      :label="item.countryName"
                      :value="item.code"
                    >
                      <template>
                        <div class="vti__flag-wrapper">
                          <span
                            :class="`vti__flag d-inline-block ${item.code.toLowerCase()}`"
                          ></span>
                        </div>
                        <span class="ml-2"> {{ item.countryName }}</span>
                      </template>
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Reference i.e. invoice number"
                  prop="reference"
                >
                  <el-input
                    v-model="payeeForm.reference"
                    show-word-limit
                    maxlength="18"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-divider content-position="center"
                  >Notification to receiver</el-divider
                >
                <el-form-item label="Telephone" prop="phonenumber">
                  <vue-tel-input
                    ref="inputphonenumber"
                    v-model="phonenumber"
                    class="form-control-alternative"
                    @validate="validatePhoneNumber"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="email">
                  <el-input
                    v-model="payeeForm.email"
                    type="email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="card-footer">
          <div class="row justify-content-xl-center">
            <div class="col-xl-12 text-center">
              <el-button @click="backView()">Cancel</el-button>
              <el-button
                type="primary"
                @click="onSubmit()"
                v-loading="submiting"
                :disabled="submiting"
                >Submit</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <PinDialog ref="pincodedialog" @checked="pincodeChecked" />
  </div>
</template>

<script>
import PayeeApi from "@/api/payee";
import UtilsApi from "@/api/utility";
import Utils from "@/utils";
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import { getCurrencyDetail } from "@/utils/stringUtils";
export default {
  components: {
    PinDialog,
  },
  computed: {
    Currency() {
      return this.$route.params.currency.toUpperCase();
    },
    SwiftType() {
      return this.$route.params.type;
    },
    plusuid() {
      return this.$store.getters.plusuid;
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
    bankCountryCode() {
      if(this.payeeForm.bankCountrycode){
        return this.payeeForm.bankCountrycode.toLowerCase()
      }
      return "";
    },
    payeeCountryCode() {
      if(this.payeeForm.payeeCountrycode){
        return this.payeeForm.payeeCountrycode.toLowerCase();
      }
      return "";
    },
  },
  data() {
    return {
      loading: false,
      submiting: false,
      isPincode: false,
      country: [],
      currencys: [],
      usaCountry: "US",
      phonenumber: "",
      payeeCountrySelect: "",
      payeeForm: {
        currency: "",
        bankname: "",
        iban: "",
        bic: "",
        bankaddress: "",
        routingnumber: "",
        bankCountrycode: "",
        corresBankName: "",
        corresBankAddress: "",
        corresSwiftCode: "",
        name: "",
        payeeaddress: "",
        payeezipcode: "",
        payeecitystate: "",
        payeeCountrycode: "",
        reference: "",
        phonecode: "",
        phonenumber: "",
        email: "",
      },
      payeeFormRule: {
        email: [
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        bankname: [
          {
            required: true,
            message: "Please enter Bank Name",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "Please enter Beneficient Name",
            trigger: "blur",
          },
        ],
        bankaddress: [
          {
            required: true,
            message: "Please enter Bank Address",
            trigger: "blur",
          },
        ],
        iban: [
          { required: true, message: "Please enter IBAN", trigger: "blur" },
        ],
        bic: [{ required: true, message: "Please enter BIC", trigger: "blur" }],
        payeeaddress: [
          {
            required: true,
            message: "Please enter Beneficient Address",
            trigger: "blur",
          },
        ],
        payeezipcode: [
          {
            required: true,
            message: "Please enter Beneficient Post Code",
            trigger: "blur",
          },
        ],
        payeecitystate: [
          {
            required: true,
            message: "Please enter Beneficient City / State",
            trigger: "blur",
          },
        ],
        payeeCountrycode: [
          { required: true, message: "Please enter Country", trigger: "blur" },
        ],
        reference: [
          {
            required: true,
            message:
              "Please enter Reference i.e. invoice number must put 6-18 character",
            min: 6,
            max: 18,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    async getBankAccount() {
      let currencys = await Utils.getSwiftCurrency();
      this.currencys = this.mapCurrencyDetail(currencys);
    },
    getPayee() {
      this.loading = true;
      PayeeApi.getEdit(
        this.$route.params.currency,
        this.$route.params.type,
        this.$route.params.uid
      ).then(({ result, data, message }) => {
        this.loading = false;
        if (result) {
          this.payeeForm.name = data.name;
          this.payeeForm.currency = data.atwcurrency;
          this.payeeForm.bankname = data.bankname;
          this.payeeForm.iban = data.accountnumber;
          this.payeeForm.bic = data.sortcode;
          this.payeeForm.bankaddress = data.bankaddress;
          this.payeeForm.routingnumber = data.routingnumber;
          this.payeeForm.bankCountrycode = data.bankcountrycode;
          this.payeeForm.corresBankName = data.corresBankName;
          this.payeeForm.corresBankAddress = data.corresBankAddress;
          this.payeeForm.corresSwiftCode = data.corresSwiftCode;
          this.payeeForm.payeeaddress = data.payeeaddress;
          this.payeeForm.payeezipcode = data.payeezipcode;
          this.payeeForm.payeecitystate = data.payeecityState;
          this.payeeForm.payeeCountrycode = data.payeecountrycode;
          this.payeeForm.reference = data.reference;
          this.payeeForm.phonecode = data.phonecode;
          this.payeeForm.phonenumber = data.phonenumber;
          this.payeeForm.email = data.email;
          this.phonenumber = data.phonecode + data.phonenumber;
          if(!data.phonenumber){
            setTimeout(()=>{
              this.countryChanged(data.payeecountrycode)
            }, 400)
            
          }
          this.$emit("payee-name", data.name);
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },
    getCountry() {
      UtilsApi.getCountry().then(({ result, data }) => {
        if (result) {
          this.country = data;
        }
      });
    },
    pincodeChecked(pincode) {
      this.isPincode = pincode;
      if (!pincode) {
        this.submiting = false;
      } else {
        this.onSubmit();
      }
    },
    onSubmit() {
      this.$refs.payeeForm.validate((valid) => {
        if (valid) {
          this.submiting = true;
          let formData = JSON.parse(JSON.stringify(this.payeeForm));
          if(formData.phonenumber){
            formData.phonenumber = formData.phonenumber.replace(/\s/g, "");
          }else{
            formData.phonecode = ""
            formData.phonenumber = "";
          }

          if (!this.isPincode && this.isMobile) {
            this.$refs.pincodedialog.show("bene-update");
            return false;
          }

          PayeeApi.putEdit(
            this.$route.params.currency,
            this.$route.params.type,
            this.$route.params.uid,
            formData
          ).then(
            ({ result, data, message }) => {
              this.submiting = false;
              if (result) {
                if (data.scapending) {
                  this.socketSubscription();
                } else {
                  this.$swal(
                    `Message`,
                    `Update Payee is success`,
                    "success"
                  ).then(() => {
                    this.backView();
                  });
                }
              } else {
                this.$swal("Message", message, "error");
              }
            },
            (err) => {
              this.submiting = false;
            }
          );
        }
      });
    },
    backView() {
      this.$router.push(
        `/payee/view/${this.$route.params.currency}/${this.$route.params.type}`
      );
    },
    socketSubscription() {
      this.$socket.connect();
      this.$swalTimer(
        "Update Payee Pending",
        "Please confirm update payee from your mobile device",
        "warning"
      );
      this.$swal.showLoading();
      this.sockets.subscribe("bene-update:" + this.plusuid, (data) => {
        this.$swal.close();
        this.$socket.disconnect();

        if (data.result) {
          this.$swal.fire({
            icon: "success",
            title: "Update Payee is success",
            showConfirmButton: false,
          });
          setTimeout(() => {
            this.$swal.close();
            this.backView();
          }, 2000);
        } else {
          this.$swal("Message", data.message, "error");
        }
      });
    },
    mapCurrencyDetail(currencys) {
      let output = [];
      let currencyDetail = getCurrencyDetail();
      currencys.map((currency) => {
        output.push({
          currency: currency,
          detail: currencyDetail[currency],
        });
      });

      return output;
    },
    validatePhoneNumber(data) {
      console.log(data)
      this.payeeForm.phonecode = "+" + data.countryCallingCode;
      this.payeeForm.phonenumber = data.nationalNumber;
      this.payeeForm.payeeCountrycode = data.countryCode;
     
    },

    countryChanged(code) {
      this.$refs["inputphonenumber"].choose(code);    
    },
  },
  created() {
    this.getCountry();
    this.getBankAccount();
    this.getPayee();
    
  },
};
</script>

<style></style>
