<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card mb-3">
        <div class="card-header position-relative">
          <h3 class="mb-0">Edit Payees</h3>
        </div>

        <div class="card-body min-vh" v-loading="loading">
          <el-form
            v-if="payeeForm"
            ref="payeeForm"
            :model="payeeForm"
            :rules="payeeFormRule"
            :label-position="labelPosition"
            size="small"
            label-width="150px"
          >
            <div class="row justify-content-md-center">
              <div class="col-xl-6 col-lg-8 col-md-12">
                <el-form-item label="Type" prop="type">
                  <el-select
                    v-model="payeeForm.type"
                    placeholder="please select type"
                    class="form-control-alternative"
                    @change="clearValidate()"
                  >
                    <el-option label="Person" value="person"></el-option>
                    <el-option label="Company" value="company"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="Currency">
                  <el-input
                    readonly
                    :value="Currency"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Payee Name" prop="name">
                  <el-input
                    v-model="payeeForm.name"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <SelectBankGBP
                  v-if="Currency === 'GBP'"
                  ref="select-bank-gbp"
                  @onChange="bankSelect"
                />

                <el-form-item
                  label="Bank name"
                  prop="bankname"
                  v-if="Currency === 'EUR'"
                >
                  <el-input
                    v-model="payeeForm.bankname"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Account Number"
                  prop="accountnumber"
                  v-if="payeeForm.currency.toUpperCase() === 'GBP'"
                >
                  <el-input
                    v-model="payeeForm.accountnumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Sortcode"
                  prop="sortcode"
                  v-if="payeeForm.currency.toUpperCase() === 'GBP'"
                >
                  <el-input
                    v-model="payeeForm.sortcode"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="IBAN"
                  prop="iban"
                  v-if="payeeForm.currency.toUpperCase() === 'EUR'"
                >
                  <el-input
                    v-model="payeeForm.iban"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="BIC"
                  prop="bic"
                  v-if="payeeForm.currency.toUpperCase() === 'EUR'"
                >
                  <el-input
                    v-model="payeeForm.bic"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Reference" prop="reference">
                  <el-input
                    v-model="payeeForm.reference"
                    show-word-limit
                    maxlength="18"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-divider content-position="left"
                  >Notification to receiver</el-divider
                >
                <el-form-item label="Telephone" prop="phonenumber">
                  <vue-tel-input
                    v-model="phonenumber"
                    class="form-control-alternative"
                    @validate="validatePhoneNumber"
                    @country-changed="changePhoneCountry"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="email">
                  <el-input
                    v-model="payeeForm.email"
                    type="email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>
              </div>
            </div>

            <b>Trading address if it different from above address </b>
            <el-switch v-model="moreinfo" @change="changeMoreinfo"></el-switch>

            <div class="row justify-content-md-center" v-if="moreinfo">
              <div
                class="col-xl-6 col-lg-8 col-md-12"
                v-if="payeeForm.type === 'person'"
              >
                <el-divider>Personal info</el-divider>

                <el-form-item label="Telephone" prop="personal.telephone">
                  <!-- <el-input v-model="payeeForm.personal.telephone" v-input-filter class="form-control-alternative"></el-input> -->
                  <vue-tel-input
                    v-model="payeeForm.personal.telephone"
                    :autoDefaultCountry="false"
                    class="form-control-alternative"
                    ref="inputphonenumber-person"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="personal.email">
                  <el-input
                    v-model="payeeForm.personal.email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Nationality" prop="personal.nationality">
                  <el-select
                    v-model="payeeForm.personal.nationality"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country.filter((c) => c.nationality)"
                      :key="item.id"
                      :label="item.nationality"
                      :value="item.nationality"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Resident Country"
                  prop="personal.residentCountry"
                >
                  <el-select
                    v-model="payeeForm.personal.residentCountry"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country"
                      :key="item.id"
                      :label="item.countryName"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Address Number"
                  prop="personal.addressnumber"
                >
                  <el-input
                    v-model="payeeForm.personal.addressnumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Street" prop="personal.street">
                  <el-input
                    v-model="payeeForm.personal.street"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="City" prop="personal.city">
                  <el-input
                    v-model="payeeForm.personal.city"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Zipcode" prop="personal.zipcode">
                  <el-input
                    v-model="payeeForm.personal.zipcode"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Region" prop="personal.region">
                  <el-input
                    v-model="payeeForm.personal.region"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Country" prop="personal.countrycode">
                  <el-select
                    v-model="payeeForm.personal.countrycode"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country"
                      :key="item.id"
                      :label="item.countryName"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Identify Type"
                  prop="personal.identifyType"
                >
                  <el-select
                    v-model="payeeForm.personal.identifyType"
                    class="form-control-alternative"
                  >
                    <el-option label="Passport" value="passport"></el-option>
                    <el-option
                      label="Driving Licence"
                      value="driving-licence"
                    ></el-option>
                    <el-option
                      label="Identity Card"
                      value="identity-card"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Identify Number"
                  prop="personal.identifyNumber"
                >
                  <el-input
                    v-model="payeeForm.personal.identifyNumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Identify Expire"
                  prop="personal.identifyExpire"
                >
                  <el-input
                    v-model="payeeForm.personal.identifyExpire"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="PEP" prop="personal.pep">
                  <el-switch v-model="payeeForm.personal.pep"></el-switch>
                </el-form-item>

                <el-form-item
                  label="PEP Type"
                  prop="personal.pepType"
                  v-if="payeeForm.personal.pep"
                >
                  <el-select
                    v-model="payeeForm.personal.pepType"
                    class="form-control-alternative"
                  >
                    <el-option label="Direct" value="direct"></el-option>
                    <el-option
                      label="Close associate"
                      value="close-associate"
                    ></el-option>
                    <el-option
                      label="Former pep"
                      value="former-pep"
                    ></el-option>
                    <el-option
                      label="Family member"
                      value="family-member"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="PEP Notes"
                  prop="personal.pepNotes"
                  v-if="payeeForm.personal.pep"
                >
                  <el-input
                    v-model="payeeForm.personal.pepNotes"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>
              </div>

              <div
                class="col-xl-5 col-lg-8 col-md-12"
                v-if="payeeForm.type === 'company'"
              >
                <el-divider>Company info</el-divider>

                <el-form-item label="Trading Name" prop="company.tradingName">
                  <el-input
                    v-model="payeeForm.company.tradingName"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Financial"
                  prop="company.regulateFinancial"
                >
                  <el-switch
                    v-model="payeeForm.company.regulateFinancial"
                  ></el-switch>
                </el-form-item>

                <el-form-item
                  label="Registration Number"
                  prop="company.registrationNumber"
                >
                  <el-input
                    v-model="payeeForm.company.registrationNumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Nature of business"
                  prop="company.natureofbusiness"
                >
                  <el-input
                    v-model="payeeForm.company.natureofbusiness"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Type Of Business"
                  prop="company.typeOfBusiness"
                >
                  <el-input
                    v-model="payeeForm.company.typeOfBusiness"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Web Site" prop="company.webSite">
                  <el-input
                    v-model="payeeForm.company.webSite"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="company.email">
                  <el-input
                    v-model="payeeForm.company.email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Telephone" prop="company.telephone">
                  <!-- <el-input v-model="payeeForm.company.telephone" class="form-control-alternative"></el-input> -->
                  <vue-tel-input
                    v-model="payeeForm.company.telephone"
                    :autoDefaultCountry="false"
                    class="form-control-alternative"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item
                  label="Address Numbe"
                  prop="company.tradingAddress.addressnumber"
                >
                  <el-input
                    v-model="payeeForm.company.tradingAddress.addressnumber"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Street"
                  prop="company.tradingAddress.street"
                >
                  <el-input
                    v-model="payeeForm.company.tradingAddress.street"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="City" prop="company.tradingAddress.city">
                  <el-input
                    v-model="payeeForm.company.tradingAddress.city"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Zipcode"
                  prop="company.tradingAddress.zipcode"
                >
                  <el-input
                    v-model="payeeForm.company.tradingAddress.zipcode"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Region"
                  prop="company.tradingAddress.region"
                >
                  <el-input
                    v-model="payeeForm.company.tradingAddress.region"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Country"
                  prop="company.tradingAddress.countrycode"
                >
                  <el-select
                    v-model="payeeForm.company.tradingAddress.countrycode"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country"
                      :key="item.id"
                      :label="item.countryName"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div
                class="col-xl-5 col-lg-8 col-md-12"
                v-if="payeeForm.type === 'company'"
              >
                <el-divider>Director info</el-divider>

                <el-form-item label="Director Name" prop="director.name">
                  <el-input
                    v-model="payeeForm.director.name"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Position" prop="director.position">
                  <el-input
                    v-model="payeeForm.director.position"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Telephone" prop="director.telephone">
                  <!-- <el-input v-model="payeeForm.director.telephone" class="form-control-alternative"></el-input> -->
                  <vue-tel-input
                    ref="inputphonenumber-directer"
                    v-model="payeeForm.director.telephone"
                    :autoDefaultCountry="false"
                    class="form-control-alternative"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="director.email">
                  <el-input
                    v-model="payeeForm.director.email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Nationality" prop="director.nationality">
                  <el-select
                    v-model="payeeForm.director.nationality"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country.filter((c) => c.nationality)"
                      :key="item.id"
                      :label="item.nationality"
                      :value="item.nationality"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Resident Country"
                  prop="director.residentCountry"
                >
                  <el-select
                    v-model="payeeForm.director.residentCountry"
                    filterable
                    class="form-control-alternative"
                  >
                    <el-option
                      v-for="item in country"
                      :key="item.id"
                      :label="item.countryName"
                      :value="item.code"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Identify Type"
                  prop="director.identifyType"
                >
                  <el-select
                    v-model="payeeForm.director.identifyType"
                    class="form-control-alternative"
                  >
                    <el-option label="Passport" value="passport"></el-option>
                    <el-option
                      label="Driving Licence"
                      value="driving-licence"
                    ></el-option>
                    <el-option
                      label="Identity Card"
                      value="identity-card"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="Identify Number"
                  prop="director.identifyNumber"
                >
                  <el-input
                    v-model="payeeForm.director.identifyNumber"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="Identify Expire"
                  prop="director.identifyExpire"
                >
                  <el-input
                    v-model="payeeForm.director.identifyExpire"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

                <el-form-item label="PEP" prop="director.pep">
                  <el-switch v-model="payeeForm.director.pep"></el-switch>
                </el-form-item>

                <el-form-item
                  label="PEP Type"
                  prop="director.pepType"
                  v-if="payeeForm.director.pep"
                >
                  <el-select
                    v-model="payeeForm.director.pepType"
                    class="form-control-alternative"
                  >
                    <el-option label="Direct" value="direct"></el-option>
                    <el-option
                      label="Close associate"
                      value="close-associate"
                    ></el-option>
                    <el-option
                      label="Former pep"
                      value="former-pep"
                    ></el-option>
                    <el-option
                      label="Family member"
                      value="family-member"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                  label="PEP Note"
                  prop="director.pepNotes"
                  v-if="payeeForm.director.pep"
                >
                  <el-input
                    v-model="payeeForm.director.pepNotes"
                    v-input-filter
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="card-footer">
          <div class="row justify-content-xl-center">
            <div class="col-xl-12 text-center">
              <el-button @click="backView()">Cancel</el-button>
              <el-button
                type="primary"
                @click="onSubmit()"
                v-loading="submiting"
                :disabled="submiting"
                >Submit</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <PinDialog ref="pincodedialog" @checked="pincodeChecked" />
  </div>
</template>

<script>
import PayeeApi from "@/api/payee";
import PayeeModel from "./PayeeModel";
import UtilsApi from "@/api/utility";
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import SelectBankGBP from "./select-bank-gbp/SelectBankGBP";
export default {
  components: {
    PinDialog,
    SelectBankGBP,
  },
  computed: {
    Currency() {
      return this.$route.params.currency.toUpperCase();
    },
    plusuid() {
      return this.$store.getters.plusuid;
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
  },
  data() {
    return {
      loading: false,
      submiting: false,
      moreinfo: false,
      phonenumber: "",
      phonecodeCountry: "",
      country: [],
      isPincode: false,
      payeeForm: PayeeModel.form(),
      payeeFormRule: PayeeModel.rule,
    };
  },

  methods: {
    getPayee() {
      this.loading = true;
      PayeeApi.getEdit(
        this.$route.params.currency,
        this.$route.params.type,
        this.$route.params.uid
      ).then(({ result, data, message }) => {
        this.loading = false;
        if (result) {
          data.abbs.name = data.payee.name;
          data.abbs.reference = data.payee.reference;
          data.abbs.bankname = data.payee.bankname;
          data.abbs.phonecode = data.payee.phonecode
          data.abbs.phonenumber = data.payee.phonenumber
          this.phonenumber = data.payee.phonecode+  data.payee.phonenumber
          data.abbs.email = data.payee.email
          Object.assign(this.payeeForm, data.abbs);

          if (this.Currency == "GBP") {
            this.$refs["select-bank-gbp"].setBank(this.payeeForm.bankname);
          }
          this.$emit("payee-name", data.abbs.name);
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },
    bankSelect(bank) {
      //console.log(bank)
      this.payeeForm.bankname = bank.name;
    },
    getCountry() {
      UtilsApi.getCountry().then(({ result, data }) => {
        if (result) {
          this.country = data;
        }
      });
    },
    clearValidate() {
      this.$refs.payeeForm.clearValidate();
    },
    clearMoreinfo() {
      if (!this.moreinfo) {
        const model = PayeeModel.form();
        this.payeeForm.company = model.company;
        this.payeeForm.director = model.director;
        this.payeeForm.personal = model.personal;
      }
    },

    pincodeChecked(pincode) {
      this.isPincode = pincode;
      if (!pincode) {
        this.submiting = false;
      } else {
        this.onSubmit();
      }
    },

    onSubmit() {
      this.$refs.payeeForm.validate((valid) => {
        if (valid) {
          this.submiting = true;
          this.clearMoreinfo();
          let formData = JSON.parse(JSON.stringify(this.payeeForm));
          if(formData.phonenumber){
            formData.phonenumber = formData.phonenumber.replace(/\s/g, "");
          }else{
            formData.phonecode = ""
            formData.phonenumber = "";
          }
          formData.company.telephone = formData.company.telephone.replace(
            /\s/g,
            ""
          );
          formData.director.telephone = formData.director.telephone.replace(
            /\s/g,
            ""
          );
          formData.personal.telephone = formData.personal.telephone.replace(
            /\s/g,
            ""
          );

          if (!this.isPincode && this.isMobile) {
            this.$refs.pincodedialog.show("bene-update");
            return false;
          }

          PayeeApi.putEdit(
            this.$route.params.currency,
            this.$route.params.type,
            this.$route.params.uid,
            formData
          ).then(
            ({ result, data, message }) => {
              this.submiting = false;
              if (result) {
                if (data.scapending) {
                  this.socketSubscription();
                } else {
                  this.$swal(
                    `Message`,
                    `Update Payee is success`,
                    "success"
                  ).then(() => {
                    this.backView();
                  });
                }
              } else {
                this.$swal("Message", message, "error");
              }
            },
            (err) => {
              this.submiting = false;
            }
          );
        }
      });
    },
    socketSubscription() {
      this.$socket.connect();
      this.$swalTimer(
        "Update Payee Pending",
        "Please confirm update payee from your mobile device",
        "warning"
      );
      this.$swal.showLoading();
      this.sockets.subscribe("bene-update:" + this.plusuid, (data) => {
        this.$swal.close();
        this.$socket.disconnect();

        if (data.result) {
          this.$swal.fire({
            icon: "success",
            title: "Update Payee is success",
            showConfirmButton: false,
          });
          setTimeout(() => {
            this.$swal.close();
            this.backView();
          }, 2000);
        } else {
          this.$swal("Message", data.message, "error");
        }
      });
    },
    backView() {
      this.$router.push(
        `/payee/view/${this.$route.params.currency}/${this.$route.params.type}`
      );
    },
    validatePhoneNumber(data) {
      this.payeeForm.phonecode = "+" + data.countryCallingCode;
      this.payeeForm.phonenumber = data.nationalNumber;
      this.phonecodeCountry = data.countryCode;
      if (this.moreinfo) {
        if (this.payeeForm.type == "person") {
          this.payeeForm.personal.telephone = data.number;
        } else {
          this.payeeForm.personal.director = data.number;
        }
      }
    },
    changePhoneCountry(data) {
      this.phonecodeCountry = data.iso2;
      if (this.moreinfo) {
        let getphonenumber =
          this.payeeForm.phonecode + this.payeeForm.phonenumber;
        let notempty = this.payeeForm.phonecode && this.payeeForm.phonenumber;
        if (this.payeeForm.type == "person") {
          this.$refs["inputphonenumber-person"] &&
            this.$refs["inputphonenumber-person"].choose(this.phonecodeCountry);
          if (notempty) this.payeeForm.personal.telephone = getphonenumber;
        } else {
          this.$refs["inputphonenumber-director"] &&
            this.$refs["inputphonenumber-director"].choose(
              this.phonecodeCountry
            );
          if (notempty) this.payeeForm.director.telephone = getphonenumber;
        }
      }
    },
    changeMoreinfo() {},
  },
  created() {
    this.getPayee();
    this.getCountry();
  },
};
</script>

<style></style>
