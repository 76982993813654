<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row justify-content-md-center">
            <div class="col-sm-12 text-center">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/payee' }">Manage Payee</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: `/payee/view/${$route.params.currency}/${$route.params.type}` }">{{ $titlePayeeType($route.params.currency) }}</el-breadcrumb-item>
                <el-breadcrumb-item>Payee Edit: {{ payeeName  }}</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
        </div>
      </div>
      <PayeeEditGBPEUR v-if="[typeGBP, typeEUR].includes(Currency)" v-on:payee-name="setPayeeName"/>
      <PayeeEditTHB v-if="[typeTHB].includes(Currency)" v-on:payee-name="setPayeeName"/>
      <PayeeEditSWT v-if="[typeSWT].includes(Currency)" v-on:payee-name="setPayeeName"/>
    </div>
  </div>
</template>

<script>
import PayeeEditGBPEUR from './components/PayeeEditGBPEUR.vue';
import PayeeEditTHB from './components/PayeeEditTHB.vue';
import PayeeEditSWT from './components/PayeeEditSWT.vue';
export default {
  components:{
    PayeeEditGBPEUR,
    PayeeEditTHB,
    PayeeEditSWT
  },
  computed: {
    Currency() {
      return this.$route.params.currency;
    },
  },
  data(){
    return {
      payeeName: '',
      typeGBP : 'gbp',
      typeEUR : 'eur',
      typeTHB : 'thb',
      typeSWT : 'swt' 
    }
  },
  methods:{
    setPayeeName(payeeName){
      this.payeeName = payeeName
    }
  }
}
</script>

<style>

</style>