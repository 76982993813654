<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card mb-3">
        <div class="card-header position-relative">
          <h3 class="mb-0">Edit Payees</h3>
        </div>

        <div class="card-body min-vh" v-loading="loading">
          <el-form
          v-if="payeeForm"
            ref="payeeForm"
            :model="payeeForm"
            :rules="payeeFormRule"
            :label-position="labelPosition"
            size="small"
            label-width="150px"
          >
            <div class="row justify-content-md-center">
              <div class="col-xl-6 col-lg-8 col-md-12">
      
                <el-form-item label="Currency">
                  <el-input readonly :value="Currency" class="form-control-alternative"></el-input>
                </el-form-item>
           
                <el-form-item label="Payee Name" prop="name">
                  <el-input v-model="payeeForm.name" v-input-filter class="form-control-alternative"></el-input>
                </el-form-item>

                <SelectBankTH ref="select-bank-th" @onChange="bankSelect"/>

                <el-form-item label="Account Number" prop="accountnumber">
                  <el-input v-model="payeeForm.accountnumber" v-input-filter class="form-control-alternative"></el-input>
                </el-form-item>

                <el-form-item label="BIC" prop="bic">
                  <el-input v-model="payeeForm.bic" readonly v-input-filter class="form-control-alternative"></el-input>
                </el-form-item>

                <el-form-item label="Branch" prop="bankaddress">
                  <el-input v-model="payeeForm.bankaddress" v-input-filter class="form-control-alternative"></el-input>
                </el-form-item>
           
                <el-form-item label="Province" prop="citystate">
                  <el-input v-model="payeeForm.citystate" v-input-filter class="form-control-alternative"></el-input>
                </el-form-item>

                <el-form-item label="Reference" prop="reference">
                  <el-input v-model="payeeForm.reference" show-word-limit maxlength="18" v-input-filter class="form-control-alternative"></el-input>
                </el-form-item>

                <el-divider content-position="left"
                  >Notification to receiver</el-divider
                >
                <el-form-item label="Telephone" prop="phonenumber">
                  <vue-tel-input
                    v-model="phonenumber"
                    defaultCountry="th"
                    class="form-control-alternative"
                    @validate="validatePhoneNumber"
                  >
                    <template slot="arrow-icon">
                      <span class="vti__dropdown-arrow">▼</span>
                    </template>
                  </vue-tel-input>
                </el-form-item>

                <el-form-item label="Email Address" prop="email">
                  <el-input
                    v-model="payeeForm.email"
                    type="email"
                    class="form-control-alternative"
                  ></el-input>
                </el-form-item>

              </div>
            </div>

          </el-form>
        </div>
        <div class="card-footer">
          <div class="row justify-content-xl-center">
            <div class="col-xl-12 text-center">
              <el-button @click="backView()">Cancel</el-button>
              <el-button type="primary" @click="onSubmit()" v-loading="submiting" :disabled="submiting">Submit</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PinDialog ref="pincodedialog"  @checked="pincodeChecked"/>

  </div>
</template>

<script>
import PayeeApi from "@/api/payee";
import { isMobile } from "@/utils/helpers";
import PinDialog from "@/components/PinCode/PinDialog";
import SelectBankTH from "./select-bank-th/SelectBankTH";
export default {
  components: {
    PinDialog,
    SelectBankTH
  },
  computed: {
    Currency() {
      return this.$route.params.currency.toUpperCase();
    },
    plusuid(){
      return this.$store.getters.plusuid
    },
    labelPosition() {
      return isMobile() ? "top" : "left";
    },
    isMobile() {
      return isMobile();
    },
  },
  data() {
    return {
      loading: false,
      submiting: false,
      isPincode: false,
      phonenumber: "",
      payeeForm: {
        currency: "THB",
        bankname: "",
        accountnumber: "",
        name: "",
        bankaddress: "",
        citystate: "",
        reference: "",
        phonecode: "",
        phonenumber: "",
        email: "",
        bic: ""
      },
      payeeFormRule:{
        email: [{ type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }],
        bankname: [{required: true, message: "Please select Bank Name", trigger: "blur"}],
        accountnumber: [{required: true, message: "Please enter Account munber", trigger: "blur"}],
        bic: [{required: true, message: "Please select Bank Name", trigger: "blur"}],
        name: [{required: true, message: "Please enter Payee Name", trigger: "blur"}],
        bankaddress: [{required: true, message: "Please enter Branch", trigger: "blur"}],
        citystate: [{ required: true, message: "Please enter Province", trigger: "blur" }],
        reference: [{ required: true, message: "Please enter Reference i.e. invoice number must put 6-18 character", min: 6, max: 18, trigger: ['blur', 'change'] }]
      },
      

    };
  },
  methods: {
    getPayee() {
      this.loading = true
      PayeeApi.getEdit(
        this.$route.params.currency,
        this.$route.params.type,
        this.$route.params.uid
      ).then(({ result, data, message }) => {
        this.loading = false
        if (result) {
          this.payeeForm.name = data.name
          this.payeeForm.bankname = data.bankname
          this.payeeForm.accountnumber = data.accountnumber
          this.payeeForm.bankaddress = data.bankaddress
          this.payeeForm.citystate = data.bankcityState
          this.payeeForm.reference = data.reference
          this.payeeForm.phonecode = data.phonecode;
          this.payeeForm.phonenumber = data.phonenumber;
          this.payeeForm.email = data.email;
          this.payeeForm.bic = data.sortcode;
          this.phonenumber = data.phonecode + data.phonenumber;
          this.$refs['select-bank-th'].setBank(this.payeeForm.bic)
          this.$emit('payee-name', data.name)
        } else {
          this.$swal("Message", message, "error");
        }
      });
    },
    pincodeChecked(pincode){
      this.isPincode = pincode
      if(!pincode){
        this.submiting = false
      }else{
        this.onSubmit()
      }
    },
    onSubmit(){
      this.$refs.payeeForm.validate(valid=>{
        if(valid){
          this.submiting = true
          let formData = JSON.parse(JSON.stringify(this.payeeForm))
          if(formData.phonenumber){
            formData.phonenumber = formData.phonenumber.replace(/\s/g, "");
          }else{
            formData.phonecode = ""
            formData.phonenumber = "";
          }
          

          if(!this.isPincode && this.isMobile){
            this.$refs.pincodedialog.show('bene-update')
            return false;
          }

          PayeeApi.putEdit(this.$route.params.currency,this.$route.params.type,this.$route.params.uid, formData)
          .then(({result, data, message})=>{
            this.submiting = false
            if(result){
              if(data.scapending){
                this.socketSubscription()
              }else{
                this.$swal(`Message`, `Update Payee is success`, 'success').then(()=>{
                  this.backView()
                })
              }
            }else{
              this.$swal('Message', message, 'error')
            }
          }, (err) => { 
            this.submiting = false
          })
        }
      })
    },

    backView(){
      this.$router.push(`/payee/view/${this.$route.params.currency}/${this.$route.params.type}`)
    },
    socketSubscription(){
      this.$socket.connect()
      this.$swalTimer('Update Payee Pending', 'Please confirm update payee from your mobile device', 'warning')
      this.$swal.showLoading();
      this.sockets.subscribe('bene-update:'+ this.plusuid, (data)=> {
        this.$swal.close()
        this.$socket.disconnect()
    
          if(data.result){
            this.$swal.fire({
              icon: 'success',
              title: 'Update Payee is success',
              showConfirmButton: false
            })
            setTimeout(() => {
              this.$swal.close()
              this.backView()
            }, 2000);
          }else{
            this.$swal('Message', data.message, 'error')
          }
        })
      
    },
    bankSelect(bank) {
      //console.log(bank)
      this.payeeForm.bankname = bank.name
      this.payeeForm.bic = bank.swiftcode
      this.payeeForm.bankaddress = bank.swiftcode
    },
    validatePhoneNumber(data) {
      this.payeeForm.phonecode = "+" + data.countryCallingCode;
      this.payeeForm.phonenumber = data.nationalNumber;
 
    },
  },
  created() {

    this.getPayee();
  },
};
</script>

<style>
</style>